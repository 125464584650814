/* eslint-disable */
import { gsap } from 'gsap';

const header = document.getElementById("sticky-header");
var stickyPosition = header.offsetTop + header.offsetHeight;
window.onscroll = function () {
	if (window.pageYOffset > stickyPosition) {
		header.classList.add("sticky");
		// document.querySelectorAll('body')[0].style.marginTop = header.offsetHeight + "px";
	} else {
		header.classList.remove("sticky");
		// document.querySelectorAll('body')[0].style.marginTop = "0px";
	}
};

const tl = gsap.timeline({ paused: true });

tl.to('.nav-container', {
	top: 0,
	autoAlpha: 1,
	duration: 0.3,
	ease: 'none'
})

tl.to('.menu__link', {
	y: 0,
	duration: 0.4,
	ease: "Power1.easeOut",
	stagger: 0.15,
	delay: 0.15,
})

tl.from('.nav-container__aside', {
	opacity: 0,
	y: 50,
}, '-=0.5')

/**
 * Menu event handler
 */
$('.hamburger').click((e) => {
	$(e.currentTarget).toggleClass("is-active");

	if ($('body').hasClass('is-active')) {
		tl.reverse();
	} else {
		tl.play();
	}

	$('body').toggleClass('is-active')
});


/**
 * Scroll Down to next div
 */
$('.hero__scrolldown').on('click', () => {
	$('html,body').animate({
		scrollTop: $('.hero__inner').offset().top
	});
})
