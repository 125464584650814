/* eslint-disable */
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

/**
 * General
 */

const siteHeader = document.querySelector('.header');

/**
 * Homepage
 */
const homeTitle = document.querySelector('.hero-homepage__title');
const homeSubtitle = document.querySelector('.hero-homepage__subtitle');
const hotspots = gsap.utils.toArray('.js-hotspot');

/**
 * Subpages
 */
const heroTitle = document.querySelector('.hero__title');
const heroScrolldown = document.querySelector('.hero__scrolldown');
const heroInner = document.querySelector('.hero__inner p');
const heroMeta = document.querySelector('.hero__vacancy-meta');

/**
 * Shapes
 */

document.addEventListener('DOMContentLoaded', () => {

	/**
	 * Site Header + Hero
	 */
	tl.fromTo(siteHeader, {
		y: 50
	}, {
		y: 0
	});

	tl.fromTo(homeSubtitle, {
		y: 30,
		opacity: 0,
	}, {
		y: 0,
		opacity: 1,

	});

	tl.fromTo(homeTitle, {
		y: 30,
		opacity: 0,
	}, {
		y: 0,
		opacity: 1,
	});

	ScrollTrigger.batch(hotspots, {
		start: "top bottom-=300px",
		end: 'bottom bottom',
		interval: 0.1,
		once: true,
		// markers: true,
		onEnter: elements => {
			gsap.fromTo(elements, {
				y: 50,
				opacity: 0,
			}, {
				duration: 0.5,
				y: 0,
				opacity: 1,
			})
		}
	})

	/**
	 * Sections
	 */
	//  gsap.to(".section-intro", {
	// 	yPercent: -100,
	// 	ease: "none",
	// 	scrollTrigger: {
	// 	  trigger: ".section-intro",
	// 	//   start: "top bottom", // the default values
	// 	  // end: "bottom top",
	// 	marker: true,
	// 	  scrub: true
	// 	},
	//   });

	  /**
	   * T3
	   */
	   gsap.timeline({
		scrollTrigger:{
		  trigger:'.section-intro',
		  toggleActions: 'play none play none',
		  start:'0% 0%',
		  once: true,
		  end: '+=300',
		},
		defaults:{duration:0.5, ease:'power1.out'}
	  })
	  .from('.section-about__title', {y: 50, opacity: 0})
	  .from('.section-about__text', {y: 50, opacity: 0})
	  .from('.section-about__btn', {y: 50, opacity: 0})


	  /**
	   * Vacancies
	   */
	//   gsap.timeline({
	// 	scrollTrigger:{
	// 	  trigger:'.section-vacancies',
	// 	  markers: true,
	// 	  toggleActions: 'play none play none',
	// 	  start:'bottom bottom',
	// 	  once: true,
	// 	  end: '+=300',
	// 	},
	// 	defaults:{duration:0.5, ease:'power1.out'}
	//   })
	//   .from('.section-vacancies', {y: 50, opacity: 0})

	  /**
	   * Creators
	   */
	//    gsap.timeline({
	// 	scrollTrigger:{
	// 	  trigger:'.section-creators',
	// 	  markers: true,
	// 	  toggleActions: 'play none play none',
	// 	  start:'bottom bottom',
	// 	  once: true,
	// 	  end: '+=300',
	// 	},
	// 	defaults:{duration:0.5, ease:'power1.out'}
	//   })
	//   .from('.section-creators', {y: 50, opacity: 0})


	//   gsap.timeline({
	// 	scrollTrigger:{
	// 	  trigger:'.section-projects',
	// 	  markers: true,
	// 	  toggleActions: 'play none play none',
	// 	  start:'bottom bottom',
	// 	  once: true,
	// 	  end: '+=300',
	// 	},
	// 	defaults:{duration:0.5, ease:'power1.out'}
	//   })
	//   .from('.section-projects', {y: 50, opacity: 0})


	//   gsap.timeline({
	// 	scrollTrigger:{
	// 	  trigger:'.section-open-sollicitation',
	// 	  markers: true,
	// 	  toggleActions: 'play none play none',
	// 	  start:'bottom bottom',
	// 	  once: true,
	// 	  end: '+=300',
	// 	},
	// 	defaults:{duration:0.5, ease:'power1.out'}
	//   })
	//   .from('.section-open-sollicitation', {y: 50, opacity: 0})


	//   gsap.timeline({
	// 	scrollTrigger:{
	// 	  trigger:'.section-posts',
	// 	  markers: true,
	// 	  toggleActions: 'play none play none',
	// 	  start:'bottom bottom',
	// 	  once: true,
	// 	  end: '+=300',
	// 	},
	// 	defaults:{duration:0.5, ease:'power1.out'}
	//   })
	//   .from('.section-posts', {y: 50, opacity: 0})


	gsap.timeline({ defaults: { ease: "power1.out" } })
	.from(heroTitle, {y: 50, opacity: 0, delay: .5})
	.from(heroMeta, {y:50, opacity: 0})
	.from(heroScrolldown, {y: 50, opacity: 0})
	.from(heroInner, {y: 50, opacity: 0})
	.timeScale(2)


	/**
	 * SVG animations
	 */
	 gsap.to(".section-creators__svg", {
		yPercent: -50,
		ease: "none",
		once: true,
		scrollTrigger: {
		  trigger: ".section-vacancies",
		//   start: "top bottom", // the default values
		  // end: "bottom top",
		marker: true,
		  scrub: true
		},
	  });

	  gsap.to(".section-about__shape", {
		yPercent: -50,
		ease: "none",
		once: true,
		scrollTrigger: {
		  trigger: ".section-about",
		//   start: "top bottom", // the default values
		  // end: "bottom top",
		marker: true,
		  scrub: true
		},
	  });

	  gsap.to(".section-open-sollicitation__svg", {
		yPercent: -50,
		ease: "none",
		once: true,
		scrollTrigger: {
		  trigger: ".section-projects",
		//   start: "top bottom", // the default values
		  // end: "bottom top",
		marker: true,
		  scrub: true
		},
	  });

	  gsap.to(".section-posts__svg", {
		yPercent: -100,
		ease: "none",
		once: true,
		scrollTrigger: {
		  trigger: ".section-posts",
		//   start: "top bottom", // the default values
		  // end: "bottom top",
		marker: true,
		  scrub: true
		},
	  });



});
