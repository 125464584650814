/* eslint-disable */
import axios from "axios";

const btnPosts = document.querySelector('.js-load-more-posts');
const btnVacancies = document.querySelector('.js-load-more-vacancies');

const loadMore = (selector, action) => {
	let current_page = document.querySelector(selector).dataset.page;
	let max_pages = document.querySelector(selector).dataset.max;

	let params = new URLSearchParams();
	params.append('action', action);
	params.append('current_page', current_page);
	params.append('max_pages', max_pages);

	axios.post('/wp-admin/admin-ajax.php', params)
		.then(res => {

			const result = res.data.data;

			let posts_list = document.querySelector(selector);
			posts_list.innerHTML += result;

			document.querySelector(selector).dataset.page++;

			if (document.querySelector(selector).dataset.page == document.querySelector(selector).dataset.max) {

				if (typeof (btnPosts) !== 'undefined' && btnPosts !== null) {
					btnPosts.parentNode.removeChild(btnPosts);
				}

				if (typeof (btnVacancies) !== 'undefined' && btnVacancies !== null) {
					btnVacancies.parentNode.removeChild(btnVacancies);
				}
			}
		})
}

if (typeof (btnPosts) !== 'undefined' && btnPosts !== null) {
	btnPosts.addEventListener('click', (e) => {
		loadMore('.js-posts', 'load_more_posts');
	});

}

if (typeof (btnVacancies) !== 'undefined' && btnVacancies !== null) {
	btnVacancies.addEventListener('click', (e) => {
		loadMore('.js-vacancies', 'load_more_vacancies');
	});
}
